<material-navbar
  [menuPosition]="settings.get('vebto.navbar.defaultPosition')"
  [container]="true"
></material-navbar>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css">

<div class="be-container page-container">
  <h1>{{ group?.name }}</h1>
<div class="d-flex justify-content-center">

<div class="allclick_area">
    <div class="bg-white p-4 mb-2">
      <p class="small text-black-50">クリック合計</p>  
<p class="h1">{{total_Click}}<span class="h4">回</span></p>
        </div>
    <div class="bg-white p-4">
  <p class="small text-black-50">インフルエンサー数</p>  
<p class="h1">{{this.links.total}}<span class="h4">名</span></p>
</div>
    </div>
<div class="bg-white mb-4">
<ul class="chart">
  <li *ngFor="let link of links?.data; index as i">
    <span class="text-black-50 small" style="height:{{ link.clicks_count }}px" title="{{ link.influencer_name }}" dir="{{ link.clicks_count }}回"></span>
  </li>
</ul>  
    </div>
</div>
  <p class="group-description" *ngIf="group?.description">{{ group.description }}</p>

  <div class="links-container">
    <ul
      class="group-links skeleton-links unstyled-list"
      *ngIf="!links && (loading$ | async)"
      @fadeOut
    >
      <li class="group-link skeleton-link" *ngFor="let i of [1, 2, 3, 4]">
        <skeleton class="long-url" variant="text"></skeleton>
        <skeleton class="short-url" variant="text"></skeleton>
        <skeleton class="link-description" variant="text"></skeleton>
      </li>
    </ul>
      
      <div class="table-responsive-xl bg-white p-5 all_list" @fadeIn>
  <table class="table">
      <thead>
      <tr>
        <th class="border-top-0 border-bottom-0" colspan="3"></th>
        <th class="border-top-0 border-bottom-0 text-center bg-inst p-1 text-white" colspan="6">Instagram</th>
        <th class="border-top-0 border-bottom-0 text-center bg-twitter p-1 text-white" colspan="5">Twitter</th>
      </tr>
      <tr>
        <th class="border-top-0 border-bottom-0" scope="col">#</th>
        <th class="border-top-0 border-bottom-0" scope="col">インフルエンサー</th>
        <th class="border-top-0 border-bottom-0" scope="col">合計クリック</th>
          
        <th class="border-top-0 border-bottom-0" scope="col">いいね</th>
        <th class="border-top-0 border-bottom-0" scope="col">コメント</th>
        <th class="border-top-0 border-bottom-0" scope="col">シェア</th>
        <th class="border-top-0 border-bottom-0" scope="col">ブックマーク</th>
        <th class="border-top-0 border-bottom-0" scope="col">インプレッション</th>
        <th class="border-top-0 border-bottom-0" scope="col">リーチ</th>
          
        <th class="border-top-0 border-bottom-0" scope="col">いいね</th>
        <th class="border-top-0 border-bottom-0" scope="col">リツイート</th>
        <th class="border-top-0 border-bottom-0" scope="col">リプライ</th>
        <th class="border-top-0 border-bottom-0" scope="col">フォロー</th>
        <th class="border-top-0 border-bottom-0" scope="col">インプレッション</th>
      </tr>
    </thead>
    <tbody>
   <tr *ngFor="let link of links?.data ; index as i">
     <th scope="row">{{i + 1}}</th>
       <td>{{ link.influencer_name }}</td>
       <td class="text-center">{{ link.clicks_count }}</td>
       
       <td>{{ link.inst_like }}</td>
       <td>{{ link.inst_comment }}</td>
       <td>{{ link.inst_share }}</td>
       <td>{{ link.inst_bookmark }}</td>
       <td>{{ link.inst_impression }}</td>
       <td>{{ link.inst_reach }}</td>
       
       <td>{{ link.twitter_like }}</td>
       <td>{{ link.twitter_retweet }}</td>
       <td>{{ link.twitter_reply }}</td>
       <td>{{ link.twitter_follow }}</td>
       <td>{{ link.twitter_impression }}</td>
      </tr>
      </tbody>
  </table>
</div>

    
  </div>

  <no-results-message
    svgImage="share-link.svg"
    *ngIf="links && links.data.length === 0 && !(loading$ | async)"
  >
    <span primary-text trans>No links have been added to this group yet</span>
  </no-results-message>
</div>

<app-footer class="be-container"></app-footer>

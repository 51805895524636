import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, Input,
    NgZone,
    OnInit
} from '@angular/core';
import {Settings} from '@common/core/config/settings.service';
import {ActivatedRoute} from '@angular/router';
import {LinkGroupService} from '../shared/link/link-group.service';
import {InfiniteScroll} from '@common/core/ui/infinite-scroll/infinite.scroll';
import {LinkGroup} from '../shared/link/link-group';
import {PaginationResponse} from '@common/core/types/pagination/pagination-response';
import {Link} from '../shared/link/link';
import {BehaviorSubject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'link-group-page',
    templateUrl: './link-group-page.component.html',
    styleUrls: ['./link-group-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({opacity: 0}),
                animate('325ms ease-in', style({
                    opacity: 1,
                }))
            ])
        ]),
        trigger('fadeOut', [
            transition(':leave', [
                style({opacity: 1, position: 'absolute', left: '0', right: '0'}),
                animate('325ms ease-out', style({
                    opacity: 0
                }))
            ])
        ])
    ]
})

export class LinkGroupPageComponent extends InfiniteScroll implements OnInit {
    @Input() public group: LinkGroup;
    public links: PaginationResponse<Link>;
    public loading$ = new BehaviorSubject(false);

    constructor(
        public settings: Settings,
        private route: ActivatedRoute,
        private linkGroups: LinkGroupService,
        protected zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadMoreItems();
        var count=0;
        var flg=true;
        while(flg){
        /*
        if(++count>3){
        alert("3回以上間違えたため、一度ウインドウを閉じてお試しください");
        const div = document.querySelector('.error_message')
        div.animate([{opacity: '0'}, {opacity: '1'}], 1000)
        break;
        }
        */
        if(flg=(prompt("パスワードを入力してください")!== this.group.hash)){
        alert("パスワードが違います");
        continue;
        }
        document.querySelector('body').style.display ="block";
        }
    }

    public removeProtocol(url: string) {
        return url.replace(/(^\w+:|^)\/\//, '');
    }

    protected canLoadMore(): boolean {
        return this.links.last_page > this.links.current_page;
    }

    protected isLoading(): boolean {
        return this.loading$.value;
    }

    protected loadMoreItems() {
        document.querySelector('body').style.display ="none";

        this.loading$.next(true);
        this.linkGroups.links(this.group.id, {page: this.links?.current_page + 1})
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe(response => {
                this.links = {
                    ...response.pagination,
                    data: [...(this.links?.data || []), ...response.pagination.data]
                };
                this.cd.markForCheck();
                
});
            


    }


 get total_Click() {
    //全データ
let g_data = this.links.data;
//クリック合計
let click_total = g_data.reduce(function(sum, eme){
  return sum + eme.clicks_count;
}, 0);

//確認用
console.log(click_total);

    return click_total;
  }


}

